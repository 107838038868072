/* font-family */
/* easing */
#lower #main-view h2 {
  max-width: 800px;
  padding: 0 10px;
}

#lower #main-view h2 strong {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
  padding: 0 15px;
}

#lower .sec01 .sec-head p {
  text-align: center;
  font-size: 14px;
}

#lower .sec01 .sec-head p span {
  display: inline-block;
}

.btns {
  text-align: center;
  padding: 0 0 5%;
}

.btns a.btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 280px;
  padding: 15px 10px;
  color: #fff;
  background-color: #2966A2;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  transition: all .2s;
}

.btns a:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 600px) {
  #lower #main-view h2 strong {
    font-size: 20px;
    line-height: 2;
  }
}

@media screen and (min-width: 800px) {
  #lower #main-view h2 strong {
    font-size: 24px;
  }
  #lower .sec01 .sec-head p {
    font-size: 16px;
  }
  #lower .sec01 .sec-head p span {
    display: block;
  }
}

@media screen and (min-width: 800px) {
  #lower #main-view h2 strong {
    font-size: 28px;
  }
}
